var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[(_vm.numberWatchlists >= _vm.maxWatchlists && _vm.maxWatchlists > 0)?_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"text-center"},[_c('h2',[_vm._v("\n          "+_vm._s(`${_vm.$Amplify.I18n.get(
              "You have reached your watchlist limit (max"
            )} ${_vm.maxWatchlists})`)+"\n        ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('h2',[_c('nuxt-link',{attrs:{"to":`/account/subs?ref=${_vm.refCode}`}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-lock")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(`Upgrade Subscription`))])],1)],1),_vm._v(" "),_c('h2',[_vm._v("\n          "+_vm._s(`${_vm.$Amplify.I18n.get("Or delete another watchlist to continue")}`)+"\n        ")])])],1)],1):_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"500"}},[_c('v-card-title',{staticClass:"text-h6 font-weight-regular justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.currentTitle))]),_vm._v(" "),_c('v-btn',{attrs:{"color":"primaryAction","icon":""},on:{"click":_vm.closeCard}},[_c('v-icon',[_vm._v("close")])],1)],1),_vm._v(" "),(_vm.error)?[_c('v-card-text',{attrs:{"align-center":""}},[_vm._v("\n            "+_vm._s(_vm.$Amplify.I18n.get(
                "There has been an error - please try again or contact support"
              ))+"\n          ")])]:_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$Amplify.I18n.get('Watchlist Title'),"placeholder":_vm.$Amplify.I18n.get('New Watchlist Title'),"color":"primaryAction","autofocus":""},model:{value:(_vm.listTitle),callback:function ($$v) {_vm.listTitle=$$v},expression:"listTitle"}}),_vm._v(" "),_c('span',{staticClass:"text-caption grey--text text--darken-1"},[_vm._v("\n                "+_vm._s(_vm.$Amplify.I18n.get(
                    "This is the title for your new Watchlist (can change later)"
                  ))+"\n              ")])],1)],1),_vm._v(" "),_c('v-window-item',{attrs:{"value":2}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$Amplify.I18n.get('Watchlist Description'),"placeholder":_vm.$Amplify.I18n.get(
                    'Description of what watchlist will contain'
                  ),"color":"primaryAction","autofocus":""},model:{value:(_vm.listDescription),callback:function ($$v) {_vm.listDescription=$$v},expression:"listDescription"}}),_vm._v(" "),_c('span',{staticClass:"text-caption grey--text text--darken-1"},[_vm._v("\n                "+_vm._s(_vm.$Amplify.I18n.get(
                    "Describe what this watchlist contains (can change later)"
                  ))+"\n              ")])],1)],1),_vm._v(" "),_c('v-window-item',{attrs:{"value":3}},[_c('div',{staticClass:"pa-3 text-center"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primaryAction","indeterminate":""}})],1)]),_vm._v(" "),_c('v-window-item',{attrs:{"value":4}},[_c('div',{staticClass:"pa-3 text-center"},[_c('h3',{staticClass:"text-h6 font-weight-light mb-2"},[_vm._v("\n                "+_vm._s(_vm.I18nFn("Select Stock Tickers"))+"\n              ")])])])],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),(!_vm.error)?_c('v-card-actions',[(_vm.step < 2)?_c('v-btn',{attrs:{"disabled":_vm.step === 1,"color":"primaryAction"},on:{"click":function($event){_vm.step--}}},[_vm._v("\n            "+_vm._s(_vm.I18nFn("Back"))+"\n          ")]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.step < 2)?_c('v-btn',{attrs:{"color":"primaryAction","depressed":"","disabled":_vm.listTitle.length === 0},on:{"click":function($event){_vm.step++}}},[_vm._v("\n            "+_vm._s(_vm.I18nFn("Next"))+"\n          ")]):(_vm.step == 2)?_c('v-btn',{attrs:{"color":"primaryAction","depressed":""},on:{"click":_vm.createList}},[_vm._v("\n            "+_vm._s(_vm.I18nFn("Create Watchlist"))+"\n          ")]):_vm._e()],1):_vm._e()],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }